/* Default Style */
:root {
  --color-primary: #68e099;
  --color-text: #030303;
  --color-dark: #7c7b7b;
  --color-bg: #f2f0e6;
  --color-white: #fff;
  --color-black: #000;
  --font-primary: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-primary);
  background: var(--color-bg);
  color: var(--color-text);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

.logo {
  display: inline-block;
}

.logo .tagline {
  margin: -15px 0 0 37px;
  font-weight: 400;
  color: var(--color-text);
}

.main {
  position: relative;
  display: grid;
  grid-template-columns: 5fr 3fr;
  overflow: hidden;
}

.main > section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  min-height: 100vh;
}

.formWrap {
  position: relative;
  background: var(--color-white);
  min-width: 450px;
}

.formWrapInner {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  opacity: 0.2;
  transform: translate(5px, 20px);
  animation: fadeInUp 150ms ease 100ms forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0.2;
    transform: translate(5px, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.formWrap .logo {
  margin-bottom: 50px;
}

.absolute-buttons {
  position: absolute;
  top: 10%;
  right: 100%;
  z-index: 3;
}

.absolute-buttons a {
  padding: 10px 10px;
  display: flex;
  color: var(--color-text);
  font-weight: 700;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  min-width: 130px;
  transition: all 0.3s;
}

.absolute-buttons a + a {
  margin-top: 10px;
}

.absolute-buttons img {
  max-width: 25px;
  margin-right: 8px;
  opacity: 0;
}

.absolute-buttons a.active {
  background: var(--color-white);
  animation: btnIn 150ms ease-in 100ms forwards;
}

.absolute-buttons a.active img {
  opacity: 1;
}

@keyframes btnIn {
  0% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: -5px 3px 5px rgb(0 0 0 / 5%);
  }
}

.buttons {
  display: none;
  margin: 0 auto 50px;
}

.buttons a {
  display: inline-block;
  padding: 10px 25px;
  text-decoration: none;
  background: var(--color-black);
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-weight: 700;
  letter-spacing: 2px;
}

.buttons a:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.buttons a:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.buttons a.active {
  background: var(--color-primary);
  color: var(--color-black);
}

.inputWrap + .inputWrap {
  margin-top: 20px;
}

.formGroup {
  position: relative;
}

.inputError {
  font-size: 13px;
  color: #ff6f00;
  letter-spacing: 1px;
}

.formGroup .form-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--color-primary);
  transition: width 0.4s;
}

.formGroup input:focus ~ .form-border {
  width: 100%;
}

.formGroup label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.formGroup input {
  width: 100%;
  font-family: var(--font-primary);
  font-size: 1rem;
  color: var(--color-text);
  border: none;
  border-bottom: 2px solid var(--color-dark);
  background: transparent;
  padding: 8px 0;
  padding-left: 35px;
}

form button[type='submit'] {
  margin-top: 25px;
  display: inline-block;
  padding: 12px 25px;
  border: none;
  border-radius: 35px;
  background: var(--color-black);
  color: var(--color-primary);
  cursor: pointer;
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  min-width: 125px;
}

form button[type='submit']:not(:disabled):hover {
  background: var(--color-primary);
  color: var(--color-black);
}

form button:disabled {
  background: var(--color-dark);
  color: var(--color-white);
  cursor: not-allowed;
}

.accountMsg {
  margin-top: 15px;
}

.formStatus {
  font-size: 13px;
  letter-spacing: 1px;
  padding: 10px;
  border-radius: 4px;
  color: var(--color-white);
}

.formStatus.success {
  background: var(--color-primary);
}

.formStatus.error {
  background: #ff6f00;
}

/* Content STYLE */
.content {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  z-index: 2;
}

.content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 50px;
}

.content h1 b {
  display: block;
  font-size: 250%;
}

.content h1 span {
  color: var(--color-primary);
}

.content h2 {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Ingredients */
.bill-goose {
  max-width: 250px;
}

.hills {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 50%;
}

.guilloche {
  position: absolute;
  top: 0;
  width: 10%;
}

.guilloche.left {
  left: 0;
}

.guilloche.right {
  text-align: right;
  right: 0;
}

.dime {
  position: absolute;
  max-width: 40px;
}

.dime-1 {
  top: 35%;
  right: 20%;
}

.dime-2 {
  bottom: 10%;
  left: 5%;
}

.dime-3 {
  bottom: 7%;
  right: 5%;
}

.goose {
  position: absolute;
  top: 40%;
  right: 2%;
  max-width: 60px;
}

.leaf {
  position: absolute;
  top: 45%;
  right: 2%;
  max-width: 150px;
}

.bill-drop {
  position: absolute;
  bottom: 20%;
  right: 3%;
  max-width: 60px;
}

@media (max-width: 1200px) {
  .formWrap {
    min-width: 350px;
  }

  .content h1 {
    font-size: 1.75rem;
  }

  .content h2 {
    font-size: 1.15rem;
  }

  .leaf {
    max-width: 80px;
  }
}

@media (max-width: 768px) {
  .contentWrap {
    display: none !important;
  }

  .buttons {
    display: flex;
  }

  .main {
    display: block;
  }
}
